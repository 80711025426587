.pitch {
	z-index: 1000000 !important;
}
.get-the-app-btn {
	max-width: 270px;
	min-width: 200px;
	text-align: center;
	padding: 10px;
	color: #f4f5f9;
	text-decoration: none;
	font-size: 1rem;
	font-weight: bold;
	cursor: pointer !important;
}
.curved-bg-blue {
	padding-top: 140px;
	min-height: 60vh;
	background: linear-gradient(to bottom, white, #d7e9f7);
}

.features {
	display: flex;
	flex-direction: column;
	width: 60%;
}
.features-heading {
	text-align: center;
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 30px;
	width: 100%;
}
.feature-contents-container {
	display: grid;
	width: 100%;
	grid-template-columns: 50% 50%;
	grid-area: auto;
	justify-content: space-between;
	padding: 10px;
}
.feature-image {
	max-height: 150px;
	align-self: center;
}
.feature-name {
	font-size: 20px;
	font-weight: bold;
}
.feature-description {
	font-size: 13px;
}

.feature-contents {
	display: flex;
	flex-direction: row;
	padding: 15px;
}
.phone-mockup {
	height: 100%;
	max-height: 500px;
	max-width: 350px;
	width: 45%;
	margin-top: 100px;
	align-self: center;
}
.sake-of-learning-header {
	margin-top: 150px;
}
.rock-star-section {
	justify-content: center;
}
.rock-star {
	height: 100%;
	max-height: 500px;
	max-width: 400px;
	width: 50%;
	margin-top: 0px;
	align-self: center;
}
.rock-star-text {
	display: flex;
	flex-direction: column;
	width: 35%;
	justify-content: center;
	padding: 30px;
}
.rock-star-heading {
	font-weight: bold;
}
.section-subheading {
	text-align: center;
	font-size: 20px;
}
.memoji-section {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-bottom: 30px;
	margin-top: 30px;
}
.memoji-section-header {
	margin-top: 100px;
	font-size: 26px;
}
.memoji {
	width: 45%;
	align-self: center;
	height: 25%;
}
.memoji-texts-container {
	height: 75%;
	text-overflow: ellipsis;
	overflow: hidden;
}
.memoji-name {
	font-size: 0.75rem;
	font-weight: bold;
	margin-bottom: 0px;
}
.memoji-comment {
	font-size: 0.7rem;
	margin-bottom: 0px;
}
.memoji-card {
	display: flex;
	flex-direction: column;
	max-width: 220px;
	width: 100%;
	max-height: 365px;
	border-radius: 15px;
	background-color: #f4f5f9;
	padding: 0 10px 10px 10px;
	margin: 20px;
	box-shadow: 0 4px 7px -2px #1d58ec;
}
@media (max-width: 850px) {
	
	.memoji-section-header {
		margin-top: 50px;
	}
	.sake-of-learning-header {
		margin-top: 190px;
	}

	.phone-mockup {
		width: 100%;
		margin: 10px;
		margin-top: 30px;
	}
	.features {
		width: 100%;
		margin: 0 auto;
	}
	.feature-contents {
		flex-direction: column;
		padding: 10px;
	}
	.rock-star-text {
		width: 100%;
		justify-content: center;
		padding: 30px 50px;
		margin: 0 auto;
	}
	.memoji-card {
		margin: 2px;
	}
	.memoji-section {
		display: grid;
		width: 100%;
		grid-template-columns: 49.5% 49.5%;
		gap: 10px; /* Adjust the value as needed for spacing */
	}
}
@media (max-width: 300px) {
	.phone-mockup {
		margin-top: 435px;
	}
}
@media (max-width: 1160px) {
}
