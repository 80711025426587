.sub-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-between;
}
.get-started-btn {
	width: 50%;
	min-width: 150px !important;
	max-width: 200px;
	text-align: center;
	padding: 10px;
	border-radius: 18px;
	color: #f4f5f9;
	text-decoration: none;
	cursor: pointer;
}
.curved-bg {
	padding-top: 80px;
}


.perks {
	display: flex;
	flex-direction: column;
	width: 100%;
	white-space: nowrap;
	height: auto;
}
.perks-contents-container,
.processes-contents-container {
	display: grid;
	width: 100%;
	grid-template-columns: 50% 50%;
	margin-top: 30px;
}
.perks-heading,
.processes-heading {
	text-align: center;
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 30px;
}
.perks-heading {
	margin-top: -50px;
}

.perk-image {
	max-height: 150px;
	align-self: center;
}
.perk-texts {
	width: 45%;
	white-space: initial;
}

.perk,
.process {
	font-size: 22px;
	font-weight: bold;
}
.perk-description,
.process-description {
	font-size: 14px;
}
.perk-contents,
.process-contents {
	display: flex;
	flex-direction: row;
	padding: 15px;
}
.create-share-earn {
	margin-top: 50px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
}
.number-count {
	background: #c4daf0;
	width: 60px;
	height: 60px;
	text-align: center;
	padding: 5px 15px;
	font-size: 30px;
	font-weight: bold;
	color: #1d58ec;
	border-radius: 100%;
}

.process-image-container {
	display: flex;
}

.process-image {
	max-height: 150px;
	width: 75%;
	align-self: center;
}
.process-texts {
	width: 33%;
	white-space: initial;
}
@media (max-width: 775px) {
	.create-share-earn {
		margin-top: 0px;
	}
	.perks,
	.processes {
		width: 100%;
		margin: 0 auto;
	}
	.perk-contents,
	.process-contents {
		flex-direction: column;
		padding: 10px;
	}
	.perk-texts,
	.process-texts {
		width: 100%;
	}
}
