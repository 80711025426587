nav {
	font-family: 'Manrope';
}
.navigation {
	width: 100vw;
	display: flex;
	flex-direction: row;
	padding: 10px 15px 10px 15px;
	position: fixed;
	z-index: 100;
	background-color: #ffffff;
}

.logo-text-image {
	max-width: 200px;
}
.logo {
	z-index: 100;
	text-decoration: none;
	color: black;
	margin-top: 9px;
	font-weight: bold;
}
.navigation a {
	font-weight: 500;
}
.header-links {
	margin-top: 20.5px;
	margin-left: 29px;
	color: black !important;
}
.closeBtn {
	opacity: 0;
	z-index: 1020;
}
.header-links a {
	color: black !important;
	margin-right: 20px;
	text-decoration: none;
}
.bars {
	opacity: 0;
	margin-top: -5px;
}
.sidenav a {
	padding: 15px 8px 0px 32px;
	text-decoration: none;
	font-size: 18px;
	color: #323232;
	display: block;
	font-weight: bold;
	opacity: 0;
}
.profile-avatar {
	display: flex;
	align-items: center;
	text-decoration: none;
	justify-self: flex-end;
	margin-top: 10px;
}

@media screen and (max-height: 450px) {
	.sidenav a {
		font-size: 18px;
	}
	.header-links {
		opacity: 0;
		display: none;
		margin-top: 0;
	}
}
@media (max-width: 600px) {
	.profile-avatar {
		display: flex;
		align-items: center;
		text-decoration: none;
		justify-self: flex-end;
		color: inherit;
		margin-right: 46px;
		z-index: 100;
		margin-top: 0px;
	}
	.header-links {
		display: none;
	}
	.navigation {
		height: 75px;
	}
	.navigation {
		padding-bottom: 0px;
	}
	.logo-text-image {
		/* width: 60vw; */
		margin-right: -10vw;
	}
	.closeBtn {
		position: static;
		margin-top: -7px;
		top: 0;
		right: 5px;
		font-size: 60px;
		margin-left: 50px;
		color: #323232;
		z-index: 100;
		opacity: 1;
		position: fixed;
		z-index: 100;
		margin-right: 10px;
	}
	.sidenav a {
		opacity: 1;
	}
	.header-links {
		opacity: 0;
		margin-top: 0;
	}
	.bars {
		opacity: 1;
		color: #323232;
		width: 10px;
		margin-right: 2.5rem;
		font-size: 45px;
		cursor: pointer;
		position: fixed;
		z-index: 100;
		right: 0;
	}
	.sidenav {
		background-color: #ffffff;
		width: 100vw;
		/* Full width */
		padding-bottom: 30px;
		/* Space at the bottom */
		opacity: 0;
		/* Start invisible */
		position: absolute;
		/* Positioned relative to the nearest positioned ancestor */
		border-bottom-left-radius: 35px;
		/* Rounded corners */
		border-bottom-right-radius: 35px;
		/* Rounded corners */
		box-shadow: 0.2px 0.2px 15px #1d58ec;
		/* Shadow for depth */
		z-index: 10;
		/* Stack order */
		margin-top: -10px;
		/* Offset for positioning */
		right: 0;
		/* Align to the right */
		top: -100%;
		/* Start above the screen (invisible) */

		padding-top: 75px;
		/* Space for content */
		font-size: 25px;
		/* Font size */
		transition: top 0.5s ease, opacity 0.5s ease; /* Smooth transition for top and opacity */
	}

	/* Class to apply when the sidenav is open */
	.sidenav.open {
		top: 0; /* Position at the top of the screen */
		opacity: 1; /* Make it visible */
	}

	/* Class to apply during transition */
	.sidenav.transitioning {
		opacity: 0; /* Fade out */
	}

	/* Class to handle the closing transition */
	.sidenav:not(.open) {
		transition: top 0.5s ease, opacity 0.5s ease; /* Smooth transition for both states */
	}
}
