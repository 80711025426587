.faq-page {
	display: flex;
	flex-direction: column;
}
.faqs-heading {
	font-size: 26px;
	font-weight: bold;
	text-align: center;
	padding: 0 10px;
}
.btn-container {
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	margin-top: 60px;
}
.curved-bg-blue {
	padding-top: 100px;
	min-height: 75vh;
	background: linear-gradient(to bottom, white, #d7e9f7);
	display: flex;
	flex-direction: column;
}

.faq-btn {
	margin: 10px;
	padding: 5px;
	width: 80vw;
	max-width: 200px;
	border: 2px solid #121212;
	border-radius: 10px;
	cursor: pointer;
	font-size: 20px;
}
.active-btn {
	background-color: #34f934;
	color: black;
}
.inactive-btn {
	color: black;
}

.faqs-container {
	margin-top: 0px;
	margin-bottom: 50px;
}
.question-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
}
.faq-panel {
	width: 90%;
	max-width: 700px;
	padding: 5px;
	margin: 0 auto;
	border-bottom: thin solid rgb(50, 50, 50, 0.1);
}
.question {
	font-size: 20px;
	padding: 10px;
}
.arrow {
	font-size: 1.5rem;
}
.answer {
	padding: 10px;
}
.faqs-image {
	max-width: 320px;
	margin: 0 auto;
	margin-top: 20px;
}

@media (max-width: 500px) {
	.faqs-container {
		/* margin-top: 80px; */
	}
	.btn-container {
		flex-direction: column;
	}
	.faq-btn {
		max-width: 400px;
	}
}
