@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap');
body {
	background: #f4f5f9;
	overflow-x: hidden;
}
.home-container {
	display: flex;
	flex-direction: column;
	width: 100vw;
	padding: 10px 0 50px 0;
}
.curved-bg {
	bottom: 0;
	left: 0;
	width: 100%;
	min-height: 60vh;
	background: radial-gradient(circle at top, #c8f5cb, white);
	border-bottom-left-radius: 50% 20%;
	border-bottom-right-radius: 50% 20%;
}

.top-image {
	width: 50%;
	max-width: 400px;
	margin: 20px;
	margin-top: -25px;
	align-self: center;
	z-index: -10;
}
.section-container {
	display: flex;
	width: 95% !important;
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
	flex-direction: row;
}
.section-heading {
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	margin: 5px;
	margin-top: 10px;
}
.landing-section {
	padding-top: 55px;
	z-index: 50;
}
.pitch {
	display: flex;
	flex-direction: column;
	z-index: -10;
	width: 50%;
	max-width: 400px;
	margin: 20px;
	align-self: center;
}
.tp {
	width: 80%;
}
.tp h1 {
	font-size: 26px;
}
.tp h2 {
	font-size: 22px;
}
.c3 {
	font-weight: bold;
}

@media (max-width: 775px) {
	.section-container {
		flex-direction: column;
		padding: 10px;
		justify-content: center;
	}
	.top-image,
	.pitch {
		width: 100%;
		margin: 10px;
		margin-top: 0;
	}
}

.beta-icon {
	background-color: #c4daf0;
	font-size: 12px;
	text-align: center;
	padding: 0 1px;
	max-width: 72px;
	font-family: 'Times', 'Times New Roman', 'serif', 'sans-serif', system-ui,
		-apple-system, BlinkMacSystemFont, 'Avenir Next', 'Avenir', 'Segoe UI',
		'Lucida Grande', 'Helvetica Neue', 'Helvetica', 'Fira Sans', 'Roboto',
		'Noto', 'Droid Sans', 'Cantarell', 'Oxygen', 'Ubuntu',
		'Franklin Gothic Medium', 'Century Gothic';
}