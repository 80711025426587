.footer {
	/* background: linear-gradient(#c4daf0, #F4F5F9); */
	background: linear-gradient(#ddf0dd, #d2e1f0);
	width: 100vw;
	height: 185px;
	border-radius: 130% / 200%;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
}
.footer-link {
	color: black;
	cursor: pointer;
	text-decoration: none;
	font-size: 14px;
}
.logo-icon {
	width: 38px;
	margin: 0 auto;
	margin-bottom: -30px;
}
.social-links {
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	margin-top: 40px;
	margin-bottom: -30px;
}
.social-icon {
	width: 16px;
	margin: 7px;
}
