@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800; /* Extra Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Light.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-ExtraLight.ttf') format('truetype');
  font-weight: 200; /* Extra Light */
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}
